footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;

  .footer__left,
  .footer__right {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    justify-content: center;
  }

  .footer__left {
    margin-right: 50px;

    img,
    p {
      align-self: center;
    }
  }
}

.footer__block {
  margin-bottom: 20px;

  &-content {
    font-size: 90%;
  }

  &-title {
    font-weight: 900;
  }
}

@media (max-width: 768px) {
  footer {
    flex-direction: column;

    .footer__left {
      margin-right: 0px;
    }

    .footer__block {
      align-self: center;
      text-align: center;
    }
  }
}
